<template>
  <div id="header">
    <div class="logo" @click="handleRoute('/admin')">
      <img
        width="70px"
        height="37px"
        src="@/assets/images/u75.png"
        alt="logo"
      />
    </div>
    <div class="text-wrapper">
      <div class="recommend text" @click="handleRoute('/newsAudt')" v-if="!isShow">新闻</div>
      <div class="group text" @click="handleRoute('/ReasearchAudit')" v-if="!isShow">
        碳研社
      </div>
      <div class="knowledge text" @click="handleRoute('/publish')" v-if="!isShow">碳知识</div>
      <div class="newPowerSystem text" @click="handleRoute('/newPowerSystem')" v-if="!isShow">
        新型电力系统
      </div>
      <div class="newPowerSystem text" @click="handleRoute('/CertiCen')">
        检测认证
      </div>
    </div>

    <div v-if="!userInfo.id" class="login-register">
      <!-- <span class="user"></span> -->
      <div class="login button" @click="handleOpenLogin('login')">登录</div>
      <div class="split"></div>
      <div class="register button" @click="handleOpenLogin('register')">
        注册
      </div>
    </div>
    <div class="user-info" v-if="token">
      <div
        class="user"
        @click="handleDropdownChange"
        @mouseleave="handleMouseLeaveLogo"
      >
        <userLogoComponent
          width="22"
          height="22"
          :disabled-hover="true"
          :user="{ avatar: userInfo.avatar }"
        />
        <div class="user-name">
          {{ userInfo.nickname }}
        </div>
        <div
          :class="['user-dropdown', active ? 'active' : '']"
          @click.stop
          @mouseenter="handleMouseEnterLogo"
          @mousedown="handleMouseLeaveLogo"
        >
          <div class="user-dropdown-user">
            <userLogoComponent
              width="24"
              height="24"
              :disabled-hover="true"
              :user="{ avatar: userInfo.avatar }"
            />
            <span class="user-name">{{ userInfo.nickname }}</span>
          </div>
          <div class="logout" @click="handleLogout">
            <logout />
            <span>退出</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import userLogoComponent from "@/components/UserLogo/index.vue";
import { logout } from "@/core/icon";
import {downloadFiles} from "@/utils/util"
export default {
  name: "Header",
  components: {
    logout,
    userLogoComponent,
  },
  data() {
    return {
      keyword: "",
      timeout: null,
      dialogVisible: false,
      active: false,
      timeout: null,
      showPublishDropdown: false,
      seleTableList: [],
      routeName:['admin','newPowerSystem','CertificationCenter'],
      type:'',
    };
  },
  activated() {
    if (this.$route.query.keyword) {
      this.keyword = this.$route.query.keyword;
    }
    this.$EventBus.$on("seleTableList", (val,type) => {
      this.seleTableList = val;
      this.type = type;
      console.log("val22", val,type);
    });
  },
  computed: {
    isShowCenter()
    {
        return this.routeName.includes(this.$route.name)
    },
    token() {
      return this.$store.state.token;
    },
    userInfo() {
      return this.$store.state.userInfo;
    },
    isShow()
    {
      return  this.userInfo.nickname && this.userInfo.username == 'houwenzhao'
    }
  },
  methods: {
    handleInput(val) {
      // console.log("handleInput", val, this.keyword);
      if (this.timeout) window.clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.handleSearch();
        this.timeout = null;
      }, 1000);
    },
    handleSearch() {
      if (this.$route.path !== "/home" && this.$route.path !== "/") {
        let text = this.$router.resolve({
          path: "/home",
          query: {
            keyword: this.keyword,
          },
        });
        // console.log("text", text);
        // 打开一个新的页面
        window.open(text.href, "_blank");
        return;
      } else {
        this.$router.push({
          // path: "/home",
          path: this.$route.path,
          query: {
            keyword: this.keyword,
          },
        });
        this.$nextTick(() => {
          this.$EventBus.$emit("search", this.keyword);
        });
        // this.$EventBus.$emit("search", this.keyword);
      }
      // this.$EventBus.$emit("search", this.keyword);
    },
    handleRoute(path, query) {
      if (path === "/admin") {
        this.$router.push({
          path,
        });
      } else {
        let text = this.$router.resolve({
          path,
          query: query || {},
        });
        // console.log("text", text.href);
        // 打开一个新的页面
        window.open(text.href, "_blank");
      }
    },
    handleDropdownChange() {
      this.active = !this.active;
      if (this.timeout) window.clearTimeout(this.timeout);
    },
    handleMouseEnterLogo() {
      if (this.timeout) window.clearTimeout(this.timeout);
      this.active = true;
    },
    handleLogout() {
      this.$store.commit("setToken", "");
      this.$store.commit("setUserInfo", { id: "" });
      localStorage.removeItem("token", "");
      window.location.reload();
    },
    handleOpenLogin(type) {
      this.$login.show({
        type,
      });
    },
    handleMouseLeaveLogo() {
      // return
      if (this.timeout) window.clearTimeout(this.timeout);
      this.timeout = window.setTimeout(() => {
        this.active = false;
      }, 500);

      // setTimeout(() => {
      //     this.active = false;
      // }, 1000)
    },
  },
};
</script>
<style lang="less">
@import "./index.less";
</style>

