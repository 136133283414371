<!--
 * @Author: 余生 906524998@qq.com
 * @Date: 2023-07-21 10:57:28
 * @LastEditors: 余生 906524998@qq.com
 * @LastEditTime: 2024-01-23 09:21:17
 * @FilePath: \cooask_frontend\src\components\Layout\AdminLayout\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div id="adminLayout" :style="path!=='/CertiCen'?{ overflow: 'auto','min-height':'unset',height:'auto'}:{}">
        <AdminHeader   v-if="shouldRenderAdminHeader "></AdminHeader>
        <div class="container">
            <keep-alive>
                <router-view></router-view>
            </keep-alive>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
    import AdminHeader from './AdminHeader/index.vue';
    import Footer from '../../Footer/index.vue';
    export default {
        name: 'AdminLayout',
        components: {
            AdminHeader,
            Footer,
        },
        data () {
            return {
                
            }
        },
        activated () {
        },
        computed: {
            path: {
                get() {
                    return this.$route.path;
                },
                set(){

                }
            },
            shouldRenderAdminHeader() {
              return this.path !== '/newsDetail' || this.path !== '/postDetail' ;
           }
        },
    }
</script>
<style lang="less">
 #adminLayout{
    overflow: hidden;
   
    height: 100%;
    min-height: 1200px;
    display: flex;
    flex-flow: column nowrap;
    padding: 60px 0 0;
 }
 .container {
    overflow: hidden;
    height: 100%;
    padding: 0 calc(50% - 600px) 0;
    // flex-grow: 1;
    // display: flex;
    // flex-flow: row nowrap;
    // justify-content: space-around;
 }
</style>